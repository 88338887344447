
import { reactive, defineComponent, ref, computed, watch } from 'vue';
import { FileItem } from '@/API/types';
import { message, Modal } from 'ant-design-vue';
import axios from 'axios';
import { supportSaveOrUpdateSingle, OCROne, OCRS, deleteDocSample } from '@/API/claim/delaerClaim/delaer';
import { b64toBlob } from '@/utils/claim/b64toBlob';

//上传扩展信息共有多少个类型，对应有多少个tabs页
interface TabsType {
  key: string; //用于循环的key
  name: string; //用于显示tabs的名称
  id: string; //上传文件的id，若没上传为空
  base64code: string; //上传的文件base64
  hasFile: boolean; //是否已经上传了文件，若已经上传，直接显示
  isUpload: boolean; //用于区别与上传和提交的显示
  type: string; //上传文件的类型，用于回显
  filePath: string; //上传文件的文件路径
  total: number; //上传文件的总数
  success: number; //上传文件的成功数
  fault: number; //上传文件的失败数
  progressPercentage: string; //上传文件的步骤信息
  isFault: boolean; //是否有错误信息
  invoicePercent: number; //进度条
  invoiceProStatus: string; //上传状态
  curUploadFile: string; //文件名
  uploadFileSize: string; //总文件大小
  uploadFileCount: number; //上传文件数目

  fileName: string;
  fileType: string;
  fileSize: string;
  documentId: string;
}

export default defineComponent({
  emits: ['fresh-table'],
  setup(prop, ctx) {
    //用于判断ORC
    const selectTabName = ref('');
    //抽屉的标题
    const title = ref('');
    //是否显示抽屉
    const visible = ref(false);

    //用于缓存初始化时从后端传进来的数据，最后确认的时候，将上传完的path合并，一起传给后端
    const cacheData = ref<any>();

    //是否是批量上传
    const isMultiple = ref(true);
    //是否，只是单独预览
    const isOnlyRead = ref(true);

    //pdf高度
    const pdfHeight = window.innerHeight - 280;

    //单个文件上传的传入数保存，包含groupid，vinNo,类型
    interface ItemMessageType {
      offerTypeId: string;
      programId: string;
      vinNo: string;
      dealerCode: string;
      offerTypeKey: string;
      retailBegin?: string;
      endTimeRetail?: string;
    }
    const itemMessage = reactive<ItemMessageType>({
      offerTypeId: '',
      programId: '',
      vinNo: '',
      retailBegin: '',
      endTimeRetail: '',
      dealerCode: '',
      offerTypeKey: ''
    });
    //已存在的数组列表
    const stepTableArray = ref<any[]>([]);
    //文件上传
    const formData = new FormData();
    const formDataOCR = new FormData();
    // 进度条
    // const curUploadFile = ref<any>({});

    //批量上传总大小
    const uploadFileListSize = ref<number>(0);
    const uploadFileCount = ref<number>(0);
    const uploadfileList = ref([]);

    const isConfirm = ref(true);

    const supportTabs = ref<TabsType[]>([]);
    //当前的tabs页
    const activeTabs = ref(0);
    //表格列头
    const columns = [
      {
        title: '文件名称',
        dataIndex: 'fileName',
        key: 'fileName',
        width: 100
      },
      {
        title: '上传失败原因',
        dataIndex: 'failedReason',
        key: 'failedReason',
        width: 100
      }
    ];
    //表格的高度
    const tableHeight = computed(() => {
      if (isMultiple.value) {
        return window.innerHeight - 460;
      } else {
        return window.innerHeight - 480;
      }
    });
    //抽屉的下方按钮显示的内容
    const buttonText = ref('关闭');
    watch(isOnlyRead, () => {
      //直接监听
      console.log('count改变了');
      if (isOnlyRead.value) {
        isConfirm.value = false;
        buttonText.value = '关闭';
      } else {
        isConfirm.value = true;
        buttonText.value = '确认';
      }
    });

    //返回的结果列表
    interface Message {
      fileName: string;
      failedReason: string;
    }
    const dataSource = ref<Message[]>();

    //关闭抽屉事件
    const closeThisDrawer = () => {
      visible.value = false;
    };
    //关闭抽屉的回调
    const afterVisibleChange = () => {
      //关闭后的事件处理
    };

    //重新上传按钮
    const isRe = ref(true);

    //多个文档上传
    const uploadFileList = (params: any, filesArray: any, filesArrayOrc1: any, filesArrayOrc2: any) => {
      isRe.value = true;
      axios({
        method: 'post',
        data: filesArray,
        url: '/claimapi/salesIncentiveClaim/uploadBatch',
        params: params,
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.lengthComputable) {
            const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            supportTabs.value[activeTabs.value].invoicePercent = complete;
            supportTabs.value[activeTabs.value].progressPercentage =
              '' +
              Math.floor((progressEvent.loaded / progressEvent.total) * uploadFileCount.value) +
              '/' +
              uploadFileCount.value +
              '' +
              (complete === 100 ? '（100% 文件加载完成）' : `（${complete}%完成）`);
          }
        }
      })
        .then((res) => {
          isConfirm.value = false;
          isRe.value = false;
          if (res.data.code === 50061 || res.data.code === 50074) {
            message.info(res.data.message);

            // supportTabs.value[activeTabs.value].isFault = false;
          } else {
            supportTabs.value[activeTabs.value].isFault = true;
            dataSource.value = res.data.data.failedResultList;
            supportTabs.value[activeTabs.value].total = res.data.data.totalNum;
            supportTabs.value[activeTabs.value].success = res.data.data.successNum;
            supportTabs.value[activeTabs.value].fault = res.data.data.failedNum;
            console.log(res.data.data.failedResultList);

            let orcUrl = '';
            console.log(selectTabName.value);
            if (selectTabName.value.trim() === '二手车发票') {
              orcUrl = '/ocr/claim/batchUCInvoice';
            } else if (selectTabName.value.trim() === '持有车辆购车发票') {
              orcUrl = '/ocr/claim/repurchase/batchUCInvoice';
            } else if (selectTabName.value.trim() === '身份证') {
              orcUrl = '/ocr/claim/batchIdCard';
            } else if (selectTabName.value.trim() === '机动车登记证') {
              orcUrl = '/ocr/claim/batchRegistration';
            }
            if (orcUrl.length > 0) {
              const fileName = [];
              for (let i = 0; i < res.data.data.failedResultList.length; i++) {
                fileName.push(res.data.data.failedResultList[i].fileName);
              }
              const param: Record<string, any> = {
                failedFileNames: fileName,
                dealerCode: itemMessage.dealerCode,
                offerTypeId: itemMessage.offerTypeId,
                progDesignId: itemMessage.programId
              };
              if (['二手车发票', '持有车辆购车发票'].includes(selectTabName.value.trim())) {
                param.claimParaSetupSupportingDocId = params.claimParaSetupSupportingDocId
              }
              if (Array.from(filesArrayOrc1.keys()).length > 0) {
                OCRS(filesArrayOrc1, param, orcUrl).then((res) => {
                  console.log(res);
                });
              }
              if (Array.from(filesArrayOrc2.keys()).length > 0) {
                OCRS(filesArrayOrc2, param, orcUrl).then((res) => {
                  console.log(res);
                });
              }
            }
          }
        })
        .catch((error) => {
          // message.error('上传失败');
          isRe.value = false;
        });
    };

    //单个上传文档
    const uploadFileSingle = (currentTab: number) => {
      //上传文件单个
      const params = {
        document: formData
      };
      isConfirm.value = true;
      isRe.value = true;
      axios({
        method: 'post',
        data: formData,
        url: '/claimapi/salesIncentiveClaim/upload',
        params: {
          claimSetupSupportDocId: cacheData.value[currentTab].claimParaSetupSupportingDocId,
          vinNo: itemMessage.vinNo
        },
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.lengthComputable) {
            const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            supportTabs.value[currentTab].invoicePercent = complete;
          }
        }
      })
        .then((res) => {
          if (res.data.code === '0') {
            //上传文件到后端，显示下方的确定按钮
            isConfirm.value = false;
            supportTabs.value[currentTab].invoicePercent = 100;
            supportTabs.value[currentTab].invoiceProStatus = 'success';

            cacheData.value[currentTab].filePath = res.data.data.docPath;
            cacheData.value[currentTab].fileName = res.data.data.docName;
            cacheData.value[currentTab].fileType = res.data.data.docType;
            cacheData.value[currentTab].fileSize = res.data.data.fileSize;
            cacheData.value[currentTab].documentId = res.data.data.id;
            isRe.value = false;
            let orcUrl = '';
            if (selectTabName.value.trim() === '二手车发票') {
              orcUrl = '/ocr/claim/ucInvoice';
            } else if (selectTabName.value.trim() === '持有车辆购车发票') {
              orcUrl = '/ocr/claim/repurchase/ucInvoice';
            } else if (selectTabName.value.trim() === '身份证') {
              orcUrl = '/ocr/claim/idCard';
            } else if (selectTabName.value.trim() === '机动车登记证') {
              orcUrl = '/ocr/claim/registration';
            }
            if (orcUrl.length > 0) {
              OCROne(
                formDataOCR,
                {
                  vinNo: itemMessage.vinNo,
                  progDesignId: itemMessage.programId,
                  offerTypeId: itemMessage.offerTypeId,
                  dealerCode: itemMessage.dealerCode,
                  claimParaSetupSupportingDocId: ['二手车发票', '持有车辆购车发票'].includes(selectTabName.value.trim())
                    ? cacheData.value[currentTab].claimParaSetupSupportingDocId
                    : undefined
                },
                orcUrl
              ).then((res) => {
                console.log(res);
              });
            }
          } else {
            message.error(res.data.message);
          }
        })
        .catch(() => {
          // message.error('上传失败');
          isRe.value = false;
        });
    };

    // 重写默认上传方法 获取合规的文件
    let endq = true;
    const invoiceCustomRequest = (source: any) => {
      if (isMultiple.value) {
        //批量上传
        const filearray = [];
        if (!endq) {
          return;
        }
        const fordata = new FormData();
        const orcForData1 = new FormData();
        const orcForData2 = new FormData();
        for (let i = 0; i < uploadfileList.value.length; i++) {
          const file = uploadfileList.value[i];
          fordata.append('document', file);
          if (i % 2 == 0) {
            orcForData1.append('file', file);
          } else {
            orcForData2.append('file', file);
          }
          // orcForData.append("files", file);
          filearray.push(fordata);
        }
        const param = {
          programId: itemMessage.programId,
          offerTypeId: itemMessage.offerTypeId,
          claimParaSetupSupportingDocId: cacheData.value[activeTabs.value].claimParaSetupSupportingDocId,
          retailBegin: itemMessage.retailBegin,
          retailEnd: itemMessage.endTimeRetail,
          fileList: filearray,
          vinNoList: [],
          offerTypeKey: itemMessage.offerTypeKey,
          dealerCode: itemMessage.dealerCode
        };
        endq = false;
        supportTabs.value[activeTabs.value].isUpload = true;
        uploadFileList(param, fordata, orcForData1, orcForData2);
      } else {
        //单个上传
        if (formData.has('document')) {
          formData.delete('document');
          formDataOCR.delete('file');
        }
        formData.append('document', source.file);
        formDataOCR.append('file', source.file);
        supportTabs.value[activeTabs.value].curUploadFile = source.file;
        const isLt5M = source.file.size / 1024 / 1024 < 10;
        if (!isLt5M) {
          message.error('文件大小不能超过10M');
          return;
        }
        supportTabs.value[activeTabs.value].uploadFileSize =
          source.file.size / 1024 > 1024
            ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
            : `${Math.floor(source.file.size / 1024)} KB`;
        supportTabs.value[activeTabs.value].isUpload = true;
        uploadFileSingle(activeTabs.value);
      }
    };
    // 上传前，多个文件上传会回调文件次数，所以用end作为标记，防止多次验证
    let end = 0;
    const beforeUpload = (file: FileItem, fileList: any) => {
      if (isMultiple.value) {
        let fault = 0;
        uploadFileListSize.value = 0;
        end++;

        if (end !== fileList.length) {
          return false;
        }

        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];
          if (file.type === 'image/jpeg' || file.type === 'application/pdf') {
            if (file.size / 1024 / 1024 < 10) {
              uploadFileListSize.value = uploadFileListSize.value + file.size / 1024 / 1024;
            } else {
              fault = 2;
            }
          } else {
            fault = 1;
          }
        }
        if (fileList.length > 30) {
          message.error('传输文件不能超过30个');
          end = 0;
          return false;
        } else if (fault === 1) {
          message.error('只能上传 jpg/pdf 格式的文件');
          end = 0;
          return false;
        } else if (fault === 2) {
          message.error('文件大小不能超过10M');
          end = 0;
          return false;
        } else {
          uploadfileList.value = fileList;
          uploadFileCount.value = fileList.length;
          supportTabs.value[activeTabs.value].uploadFileSize = uploadFileListSize.value.toFixed(2) + 'MB';
          end = 0;
          return true;
        }
      } else {
        const isJpg = file.type === 'image/jpeg';
        const isPdf = file.type === 'application/pdf';
        if (!isJpg && !isPdf) {
          message.error('jpg/pdf format files can be uploaded!');
        }
        return isJpg || isPdf;
      }
    };

    //tabs页切换的事件
    const cutTabs = (index: number) => {
      activeTabs.value = index;
      // invoicePercent.value = 0;
      // invoiceProStatus.value = 'normal'
      selectTabName.value = supportTabs.value[activeTabs.value].name;
      endq = true;
    };

    //根据传入的参数，显示指定的上传页面内容
    const showDrawer = (option: any) => {
      isOnlyRead.value = option.isOnlyRead; //只读标记
      isConfirm.value = !isOnlyRead.value;
      activeTabs.value = option.message.index; //第几个tabs
      title.value = option.isMultiple ? '批量上传文档' : '上传文档'; //标题
      isMultiple.value = option.isMultiple; //是否是多选
      supportTabs.value = []; //tabs 数组
      itemMessage.programId = option.message.programId; //programId
      itemMessage.offerTypeId = option.message.offerTypeId; //类型id
      itemMessage.vinNo = option.message.vinNo;
      itemMessage.retailBegin = option.message.retailBegin;
      itemMessage.endTimeRetail = option.message.endTimeRetail;
      itemMessage.offerTypeKey = option.message.offerTypeKey;
      itemMessage.dealerCode = option.message.dealerCode;
      cacheData.value = option.data;
      stepTableArray.value = option.stepTableArray.value;
      // invoicePercent.value = 0
      // invoiceProStatus.value = "normal"
      endq = true;
      for (let i = 0; i < option.data.length; i++) {
        const one = option.data[i];
        supportTabs.value.push({
          key: i + '',
          name: one.tabName,
          id: one.id,
          base64code:
            one.ext === 'jpg'
              ? 'data:image/jpg;base64,' + `${one.base64code}`
              : URL.createObjectURL(b64toBlob(one.base64code, 'application/pdf')),
          hasFile: one.ext === 'pdf' || one.ext === 'jpg',
          isUpload: false,
          type: one.ext,
          filePath: one.filePath,
          total: 0,
          success: 0,
          fault: 0,
          progressPercentage: '',
          isFault: false,
          invoicePercent: 0,
          invoiceProStatus: 'normal',
          curUploadFile: '',
          uploadFileSize: '',
          uploadFileCount: 0,

          fileName: one.fileName,
          fileType: one.fileType,
          fileSize: one.fileSize,
          documentId: one.documentId
        });
      }
      selectTabName.value = supportTabs.value[0].name;
      visible.value = true;
    };

    //确认按钮事件
    const confirm = () => {
      if (isMultiple.value) {
        //批量上传确认事件
        isConfirm.value = true;
        visible.value = false;
      } else {
        //单个上传确认事件
        //supportSaveOrUpdateSingle
        if (!isOnlyRead.value) {
          //进行确认的事件
          const array: any[] = [];
          for (let i = 0; i < cacheData.value.length; i++) {
            const param = {
              docId: cacheData.value[i].id,
              vinNo: itemMessage.vinNo,
              programId: itemMessage.programId,
              offerTypeId: itemMessage.offerTypeId,
              claimParaSetupSupportingDocId: cacheData.value[i].claimParaSetupSupportingDocId,
              filePath: cacheData.value[i].filePath,

              fileName: cacheData.value[i].fileName,
              fileType: cacheData.value[i].fileType,
              fileSize: cacheData.value[i].fileSize,
              documentId: cacheData.value[i].documentId
            };
            array.push(param);
          }

          const prams = {
            dealerCode: itemMessage.dealerCode,
            offerTypeKey: itemMessage.offerTypeKey,
            offerTypeId: itemMessage.offerTypeId,
            programId: itemMessage.programId,
            docList: array,
            vinNo: itemMessage.vinNo
          };

          supportSaveOrUpdateSingle(prams).then((res: any): void => {
            isConfirm.value = true;
            const uploadTypes = array.reduce((types: string[], item: any, index: number) => {
              if (item.documentId && !item.docId) {
                types.push(supportTabs.value[index].name)
              }
              return types
            }, []);
            message.success(`${uploadTypes.join("、")}上传成功`);
            visible.value = false;
          });
        } else {
          //进行关闭的事件
          visible.value = false;
        }
      }
    };
    watch(visible, (newVal: boolean) => {
      if (!isOnlyRead.value && !newVal) {
        ctx.emit('fresh-table');
      }
    });

    const reUpload = () => {
      if (isMultiple.value) {
        uploadFileListSize.value = 0;
        uploadfileList.value = [];
        uploadFileCount.value = 0;
        supportTabs.value[activeTabs.value].uploadFileSize = 0 + 'MB';
        supportTabs.value[activeTabs.value].hasFile = false;
        supportTabs.value[activeTabs.value].isUpload = false;
        cacheData.value[activeTabs.value].filePath = '';

        cacheData.value[activeTabs.value].fileName = '';
        cacheData.value[activeTabs.value].fileType = '';
        cacheData.value[activeTabs.value].fileSize = '';
        cacheData.value[activeTabs.value].documentId = '';

        isConfirm.value = true;
        isOnlyRead.value = false;
        supportTabs.value[activeTabs.value].invoicePercent = 0;
        supportTabs.value[activeTabs.value].progressPercentage = '';
        supportTabs.value[activeTabs.value].isFault = false;
        dataSource.value = [];
        endq = true;
      } else {
        supportTabs.value[activeTabs.value].base64code = '';
        supportTabs.value[activeTabs.value].type = '';
        supportTabs.value[activeTabs.value].filePath = '';
        supportTabs.value[activeTabs.value].hasFile = false;
        supportTabs.value[activeTabs.value].isUpload = false;
        cacheData.value[activeTabs.value].filePath = '';

        isConfirm.value = true;
        isOnlyRead.value = false;
      }
    };
    const deleteHandle = (docSample: { id: string }) => {
      Modal.confirm({
        title: '确认',
        content: '确定要删除这条支持文档吗？',
        okText: '确认',
        async onOk() {
          await deleteDocSample(docSample.id);
          supportTabs.value[activeTabs.value].base64code = '';
          supportTabs.value[activeTabs.value].type = '';
          supportTabs.value[activeTabs.value].filePath = '';
          supportTabs.value[activeTabs.value].hasFile = false;
          supportTabs.value[activeTabs.value].isUpload = false;

          cacheData.value[activeTabs.value].id = '';
          cacheData.value[activeTabs.value].filePath = '';
          cacheData.value[activeTabs.value].fileName = '';
          cacheData.value[activeTabs.value].fileType = '';
          cacheData.value[activeTabs.value].fileSize = '';
          cacheData.value[activeTabs.value].documentId = '';

          isConfirm.value = false;
          isOnlyRead.value = false;
          // confirm();
        },
        cancelText: '取消'
      });
    };

    // const showDelete = computed(() => {
    //   return tab
    // });

    return {
      // showDelete,
      isRe,
      reUpload,
      uploadFileCount,
      // progressPercentage,
      buttonText,
      deleteHandle,
      isOnlyRead,
      pdfHeight,
      itemMessage,
      confirm,
      isConfirm,
      // curUploadFile,
      // uploadFileSize,
      // invoiceProStatus,
      // invoicePercent,
      activeTabs,
      showDrawer,
      // isFault,
      tableHeight,
      cutTabs,
      columns,
      dataSource,
      // uploadResult,
      beforeUpload,
      invoiceCustomRequest,
      isMultiple,
      supportTabs,
      visible,
      title,
      afterVisibleChange,
      closeThisDrawer
    };
  }
});
